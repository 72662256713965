import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const ParsecLink = () => (
  <OutboundLink href="https://parsec.finance" target="_blank" rel="noopener">
    parsec.finance
  </OutboundLink>
)

export default function About() {
  return (
    <Layout>
      <div className="about">
        <h4>An Open Research House</h4>
        <p>
          Here we dig into the emerging and complex financial applications being built on Ethereum.
          The goal is to generate in depth DeFi analysis that compliments <ParsecLink />, a professional workstation for DeFi that is currently in private alpha.
        </p>
        <p>This is an experiment, those interested in contributing or just talking Open Finance, please reach out and subscribe to updates on the project!</p>
      </div>
    </Layout>
  )
}